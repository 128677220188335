import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { PracticeService } from '../../services/practice.service';

@Component({
  selector: 'app-practice',
  templateUrl: './practice.component.html',
  styleUrls: ['./practice.component.scss']
})
export class PracticeComponent implements OnInit {

  areas = [];
  selectedArea;

  constructor(
    private practiceService: PracticeService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.areas = this.practiceService.getAreas(this.translate.defaultLang);
    this.selectedArea = this.areas[0];

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.areas = this.practiceService.getAreas(event.lang);
      this.selectedArea = this.areas[0];
    });
  }

  select(area) {
    this.selectedArea = area;
  }

}
