export class LocationModel {
  lat: number;
  long: number;
  adress1: string;
  adress2: string;
  phone: string;
  email: string;
  linkedin: string
  web: string
}
