import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements AfterViewInit {
  constructor(
    private route: ActivatedRoute
  ) { }

  ngAfterViewInit() {
    this.route.fragment.subscribe((value: string) => {
      if (!value) {
        return;
      }
      const element = document.querySelector(`app-${value}`) as HTMLElement;
      if (element.clientWidth >= 767) {
        window.scrollTo({ behavior: 'smooth', top: element.offsetTop - 134 });
      } else {
        window.scrollTo({ behavior: 'smooth', top: element.offsetTop });
      }
    });

    window.addEventListener('scroll', () => {
      const elements = document.querySelectorAll('.out-view') as any;
      for (const el of elements) {
        const rect = el.getBoundingClientRect();
        const elTop = rect.top;
        const elBottom = rect.bottom;
        if (el.clientWidth < 350 && elTop >= 0 &&
          elBottom <= (window.innerHeight + 750)) {
          el.classList.remove('out-view');
        } else if (el.clientWidth < 400 && elTop >= 0 &&
          elBottom <= (window.innerHeight + 600)) {
          el.classList.remove('out-view');
        } else if (elTop >= 0 &&
          elBottom <= (window.innerHeight + 400)) {
          el.classList.remove('out-view');
        }
      }
    });
  }
}
