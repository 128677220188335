<div class="contact-wrap" id="contact">
  <div class="contact-headline">
    <div class="adress-wrap">
      <h1 class="out-view" translate="contact"></h1>
      <div class="adress out-view" [class.selected]="location == selectedLocation" *ngFor="let location of locations"
        (click)="setLocation(location)">
        <!-- <p>
          {{ location.adress1 }}
          <br>
          {{ location.adress2 }}
          <br>
          T: {{ location.phone }}
          <br>
          E: {{ location.email }}
          <br>
          Linkedin: {{ location.linkedin }}
          <br>
          {{ location.web }}
        </p> -->
        <p [innerHTML]="location.adress1"></p>
        <p [innerHTML]="location.adress2"></p>
        <p [innerHTML]="location.phone"></p>
        <p [innerHTML]="location.email"></p>
        <p [innerHTML]="location.linkedin"></p>
      </div>
    </div>
  </div>

  <div class="map">
    <!-- <app-map [locations]="locations" [location]="selectedLocation"></app-map> -->
    <agm-map 
      [latitude]="selectedLocation.lat" 
      [longitude]="selectedLocation.long"
      [zoom]="17"
      gestureHandling="none"
      disableDefaultUI="true"
      [styles]= "styles">
      <agm-marker 
        [latitude]="selectedLocation.lat" 
        [longitude]="selectedLocation.long">
      </agm-marker>
    </agm-map>
  </div>

  <div class="foot out-view">
    <p>
      © 2013. NKO Law Office 
    </p>
  </div>
</div>
