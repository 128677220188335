<div class="nav-wrap">

  <div class="nav-side"></div>

  <div class="navigation">

    <img src="../../assets/img/logo.png" alt="logo" class="logo">

    <div class="link-wrap" [style.background]="showMenu ? 'transparent' : 'white'">

      <a routerLink="/" translate="firm" (click)="navigateTo('scene')"></a>

      <a routerLink="/" translate="team" (click)="navigateTo('team')"></a>

      <a routerLink="/" translate="practiceAreas" (click)="navigateTo('practice')"></a>

      <a routerLink="/" translate="rankings" (click)="navigateTo('rankings')"></a>

      <a routerLink="/" translate="clients" (click)="navigateTo('clients')"></a>

      <a routerLink="/" translate="contact" (click)="navigateTo('contact')"></a>

    </div>

    <a class="lng" translate="lang" (click)="changeLanguage()"></a>

  </div>

  <div class="nav-side"></div>

</div>

<div class="hamburger" (click)="showMenu = !showMenu">
  <div class="ham-line" [class.hocus1]="showMenu"></div>
  <div class="ham-line" [class.hocus2]="showMenu"></div>
  <div class="ham-line" [class.hocus3]="showMenu"></div>
</div>

<div *ngIf="showMenu" class="overlay">

  <div class="pop">

    <a routerLink="/" translate="firm" (click)="navigateTo('scene'); showMenu = false;"></a>

    <a routerLink="/" translate="team" (click)="navigateTo('team'); showMenu = false"></a>

    <a routerLink="/" translate="practiceAreas" (click)="navigateTo('practice'); showMenu = false"></a>

    <a routerLink="/" translate="rankings" (click)="navigateTo('rankings'); showMenu = false"></a>

    <a routerLink="/" translate="clients" (click)="navigateTo('clients'); showMenu = false"></a>

    <a routerLink="/" translate="contact" (click)="navigateTo('contact'); showMenu = false"></a>
  </div>

  <a class="lng" translate="lang" (click)="changeLanguage()"></a>
</div>
