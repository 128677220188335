import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PracticeService {

  private english = [
    {
      title: `Banking and Finance`,
      content: `<p>We provide advice in all legal aspects of banking and financial products including syndicated lending,
      acquisition finance, corporate and project finance, real estate finance and in financial regulatory
      matters.
  </p>
  <br>
  <p>
  We help our clients to identify the most appropriate security mechanisms in order to protect their
  interest under the financial agreements.
  </p>`
    },
  //   {
  //     title: `Bankruptcy and Restructuring`,
  //     content: `<p>Significant part of our practice relates to enforcing creditors’ claims in bankruptcy and advisory work in
  //   complex bankruptcy and restructuring proceedings.
  // </p>
  // <br>
  // <p>
  //   Our expertise includes both advising companies undergoing restructuring to successfully complete this
  //   procedure, as well as advising creditors in protecting their interests and recovering their claims.
  // </p>`
  //   },
    {
      title: `Competition / Antitrust`,
      content: `<p>We work closely with our clients to develop business solutions in all aspects of competition and 
      antitrust regulation. During merger and acquisition procedures, we consider competition law aspects, and
      in structuring transactions we provide advice for optimal solutions from competition law perspective.
    </p>
    <br>
    <p>
      We assist our clients in understanding the impact of antitrust and competition legislation on their 
      day-to-day operations and how to avoid restrictive and prohibited practices.
    </p>`
    },
    {
      title: `Corporate and Commercial`,
      content: `<p>Our expertise covers a wide range of corporate and transactional work, including mergers and
      acquisitions, corporate restructuring, takeover bids, management buyouts, joint ventures, privatization
      as well as cross-border commercial transactions, supply and distribution agreements, logistic service
      agreements. Our focus is on effective advice, clear-headed strategies and profitable outcomes for our
      clients’ business.
    </p>
    <br>
    <p>
      We have assisted clients from a wide range of sectors, including energy & mining, real estate,
      telecom & media, food industry, pharmaceutical industry, tobacco industry, chemical industry,
      banking, music industry and publishing, wholesale and retail, etc. Sector-specific knowledge and a
      profound understanding of local practices and the local market help us find innovative solutions
      tailored to our clients’ needs.
    </p>`
    },
    {
      title: `Data Protection`,
      content: `<p>In this increasingly important area of law, our aim is to help clients to minimize the legal risks
      associated with data protection while minimizing interference to their business processes. We advise
      clients on complex issues associated with both personal and sensitive business data, including its
      collection, use, storage, disclosure, transfer and destruction.
    </p>
    <br>
    <p>
      Our experience in this area also involves implementation and where necessary, adjustment of data
      protection policies of multinational corporations, developed primarily based on principles of EU law,
      with Serbian legal system.
    </p>`
    },
    {
      title: `Dispute Resolution`,
      content: `<p>NKO provides advice aimed to avoid disputes and prevent litigation by making efforts to settle
      potential differences amicably, taking into consideration client’s business goals and legal and
      reputational risks at stake.
    </p>
    <br>
    <p>
      Where litigation cannot be avoided, our experienced litigators, some of whom gained significant
      experience while working at courts, represent clients before the Serbian and Montenegrin courts as
      well as before local and international arbitrations.
    </p>`
    },
    {
      title: `Employment`,
      content: `<p>Our firm is at the forefront of employment law in all its diversity. We have successfully handled senior
      executive employment contracts, negotiations of collective agreements, drafting employment by-laws
      and employment agreements, resolving employment issues related to large-scale company
      acquisitions and restructurings, advising clients on procedures for hiring and dismissing employees,
      etc.
    </p>
    <br>
    <p>
      In providing our advice, we consider not only the current legislation, but also the latest governmental
      opinions and legislative interpretations, as well as the court practice updates, as such opinions and
      practice may have considerable practical impact on our clients’ businesses.
    </p>`
    },
    {
      title: `Energy, Natural Resources and Mining`,
      content: `<p>We act for clients across all the key areas of the Energy and Natural Resources segment, including
      establishing of private public partnerships. Our experience includes providing services in mining, oil,
      gas, renewable energy and other energy sectors.
    </p>
    <br>
    <p>
      We provide valuable insight to international and local clients on rapidly changing and increasingly
      appealing energy market in Serbia and Montenegro, by sharing our knowledge of legislation and local
      practices, as well as experience gained in advising leading companies in various sectors of the
      energy market.
    </p>`
    },
    {
      title: `Environmental`,
      content: ` <p>We advise our clients on a broad array of environmental challenges, including helping them manage
      environmental risks in the context of business and real estate transactions and achieve compliance
      with environmental laws and regulations. This work includes assistance in understanding the complex
      regulatory framework and compliance with various legislative requirements and necessary filings and
      registrations.
    </p>
    <br>
    <p>
      Our goal is to obtain legal stability for clients’ business operations, and to resolve all environmental
      and related issues in a pragmatic, sustainable and balanced manner.
    </p>`
    },
    {
      title: `Healthcare & Pharmaceuticals`,
      content: `<p>We regularly advise various entities across the healthcare services and products supply chain,
      including pharmaceutical manufacturers, wholesalers, distributors and pharmacy chains.
    </p>
    <br>
    <p>
      Our services in this sector span a wide range of various areas which include advising on all regulatory
      aspects relevant for the industry. We have extensive experience advising on mergers and
      acquisitions, in a manner tailored to the specific features of healthcare and pharmaceuticals industry
      and supporting the clients in successfully completing even the most complex transactions. In addition,
      our in-depth knowledge of the sector enables us to provide focused and industry specific competition
      law advice.
    </p>`
    },
    {
      title: `Real Estate and Construction`,
      content: `<p>We provide full range of legal services relating to development, engineering, construction, leasing,
      management, structuring, financing, acquisitions and disposals of commercial, residential and other
      properties. We also have extensive experience in implementing FIDIC-based agreements in local
      jurisdiction and have advised clients in development and management of the largest shopping malls
      in the region.
    </p>
    <br>
    <p>
      Our expertise in applying the regulations in this area, and dealing with the authorities, helps our
      clients to achieve their goals, comply with the local regulations and minimize the associated costs and
      risks.
    </p>`
    },
    {
      title: `Technology, Media and Telecom`,
      content: `<p>By applying a deep understanding of telecom & media trends, developments and innovations, our
      goal is to remain closely aligned with clients’ strategic drivers and prerogatives. By providing informed,
      commercial, strategic and practical advice, we add significant value to our clients’ telecom & media
      transactions and day-to-day operations.
    </p>
    <br>
    <p>
      Our experience includes handling of some of the major telecom transactions in the region, advising on
      highly specialized telecom issues, providing full legal services related to initial investments in media
      sector, ensuring adequate legal support to IT startups, as well as participation in several regional
      projects related to reform and improvement of legislation relevant to this area of practice.
    </p>
  </div>`
    }
  ];

  private serbian = [
    {
      title: `Bankarsko pravo i finansije`,
      content: `<p>Pružamo pravne savete iz oblasti bankarskog i finansijskog prava, uključujući savete koji se odnose
      na sindicirane kredite, finansiranje kupovine privrednih društava, korporativno i projektno finansiranje,
      finansiranje projekata za razvoj nepokretnosti i savete vezano za finansijska regulatorna pitanja.
      </p>
      <br>
      <p>Klijentima pomažemo u odabiru odgovarajućih sredstava obezbeđenja u cilju zaštite njihovih interesa
      u finansijskim ugovorima i savetujemo o prednostima i manama različitih sredstava obezbeđenja.
      </p>`
    },
    // {
    //   title: `Stečaj i restrukturiranje`,
    //   content: `<p>Značajan deo naše prakse odnosi se na ostvarivanje prava poverilaca u stečajnim postupcima i na
    //   savetodavne usluge u složenim stečajnim i postupcima restrukturiranja.
    //   </p>
    //   <br>
    //   <p>Naše iskustvo uključuje kako davanje saveta kompanijama u procesu restrukturiranja do uspešnog okončanja ove procedure,
    //   tako i savetovanje poverilaca u cilju zaštite njihovih interesa i ostvarivanja njihovih zahteva.
    //   </p>`
    // },
    {
      title: `Pravo konkurencije`,
      content: `<p>Savetujemo klijente u pronalaženju odgovarajućih poslovnih rešenja usklađenih sa pravom
      konkurencije. Tokom procedure kupovine privrednih društava i statusnih promena, kao i prilikom
      strukturiranja transakcija, dajemo savete o optimalnim rešenjima u pogledu propisa kojima se reguliše
      konkurencija na tržištu.
      </p>
      <br>
      <p>Pomažemo klijentima u razumevanju uticaja prava konkurencije na njihovo svakodnevno poslovanje,
      a posebno pri izbegavanju stvaranja restriktivne ili zabranjene prakse. Savetujemo klijente prilikom
      zaključenja komercijalnih ugovora o brojim pitanjima koja su osetljiva sa aspekta prava konkurencije.
      </p>`
    },
    {
      title: `Korporativno pravo`,
      content: `<p>Naše iskustvo obuhvata širok spektar korporativnih i transakcionih poslova, uključujući spajanja,
      pripajanja i preuzimanja, korporativno restrukturiranje, ponude za preuzimanje, kupovinu privrednih
      društava od strane menadžmenta, zajednička ulaganja, privatizacije kao i prekogranične komercijalne
      transakcije, ugovore o snabdevanju i distribuciji i ugovore o pružanju logističkih usluga. Usmereni smo
      na pružanje efikasnih saveta, formiranje jasne strategije i ostvarivanje profitabilnih rezultata za
      klijente.
      </p>
      <br>
      <p>Savetovali smo klijente aktivne u različitim industrijskim oblastima, uključujući energetiku i rudarstvo,
      nekretnine, telekomunikacije i medije, prehrambenu industriju, farmaceutsku industriju, duvansku i
      hemijsku industriju, bankarstvo, muzičku industriju i izdavaštvo, veleprodaju i maloprodaju, itd. Naša
      specifična znanja za pojedine od ovih sektora, kao i duboko razumevanje lokalne prakse i lokalnog
      tržišta, pomažu nam da pronađemo inovativna rešenja koja su prilagođena potrebama klijenata.
      </p>`
    },
    {
      title: `Zaštita podataka`,
      content: `<p>Naš cilj, u ovoj sve značajnijoj oblasti prava, je da pomognemo klijentima da smanje pravne rizike
      vezane za zaštitu podataka, uz najmanji mogući uticaj na njihove poslovne procese. Savetujemo
      klijente u pogledu kompleksnih pitanja koja se tiču kako ličnih podataka tako i poverljivih poslovnih
      podataka, uključujući njihovo prikupljanje, korišćenje, čuvanje, obradu, odavanje, prenos i
      uništavanje.
      </p>
      <br>
      <p>Naše iskustvo u ovoj oblasti obuhvata i implementaciju u pravni sistem Srbije, kao i prilagođavanje,
      kada je to neophodno, politika za zaštitu podataka multinacionalnih korporacija, koje su pre svega
      zasnovane na principima prava Evropske unije.
      </p>`
    },
    {
      title: `Rešavanje sporova`,
      content: `<p>Nastojimo da pružanjem odgovarajućih saveta izbegnemo pokretanje sudskih postupaka i ulažemo
      sve napore da vansudskim putem rešimo sporne situacije, imajući u vidu poslovne ciljeve, pravni rizik
      i reputaciju klijenata.
      </p>
      <br>
      <p>U slučaju kada sporazumno rešenje nije moguće, naši iskusni advokati, od kojih su neki stekli
      značajno iskustvo za vreme rada u sudovima, zastupaju klijente pred srpskim i crnogorskim sudovima
      i pred domaćim i međunarodnim arbitražama.
      </p>`
    },
    {
      title: `Radno pravo`,
      content: `<p>Kancelarija savetuje klijente u pogledu širokog spektra pitanja iz oblasti radnog prava. Naše usluge
      uključuju: savetovanje prilikom zaključenja ugovora o radu sa starijim menadžerima, pregovore oko
      zaključenja kolektivnih ugovora, izrade pravilnika o radu i drugih opštih akata, rešavanje radno
      pravnih pitanja u vezi sa postupkom kupovine i restrukturiranja privrednih društava, savetovanje
      vezano za procedure zapošljavanja i prestanka radnog odnosa, izradu programa rešavanja viška
      zaposlenih u slučajevima otpuštanja po osnovu tehnološkog viška, kao i davanje saveta u vezi sa
      svim pitanjima koja su radnopravnog karaktera.
      </p>
      <br>
      <p>Prilikom pružanja saveta, pored važećih propisa, analiziramo i aktuelna mišljenja i tumačenja
      nadležnih organa uprave i sudsku praksu, s obzirom na to da mogu imati značajan uticaj na
      konkretna pitanja.
      </p>`
    },
    {
      title: `Energetika, prirodni resursi i rudarstvo`,
      content: `<p>Sarađujemo sa klijentima koji su aktivni u ključnim sektorima energetike i prirodnih resursa, uključujući
      i uspostavljanje javno-privatnih partnerstava. Naše iskustvo uključuje savetovanje kompanija u oblasti
      rudarstva, trgovine naftnim derivatima i prirodnim gasom, obnovljivih izvora energije, kao i u okviru
      drugih sektora energetike.
      </p>
      <br>
      <p>Pružamo međunarodnim i lokalnim klijentima bitne informacije o energetskom tržištu u Srbiji i u Crnoj
      Gori, koristeći kako naša znanja o regulativi i lokalnoj praksi, tako i iskustvo stečeno u savetovanju
      vodećih privrednih društava u različitim sektorima energetskog tržišta.
      </p>`
    },
    {
      title: `Oblast zaštite životne sredine`,
      content: `<p>Savetujemo klijente u pogledu širokog opsega pitanja koja se tiču zaštite životne sredine, uključujući i
      pomoć u upravljanju rizicima koji nastaju u vezi sa poslovnim transakcijama, i prilikom usklađivanja sa
      propisima koji regulišu zaštitu životne sredine. Naša podrška uključuje pomoć klijentima u
      razumevanju kompleksnog regulatornog okvira, usaglašavanje sa važećim propisima i sprovođenje
      odgovarajućih registracija i izveštavanja.
      </p>
      <br>
      <p>Naš cilj je da obezbedimo stabilnost poslovnih delatnosti klijenata i da rešavamo pitanja koja se
      odnose na zaštitu životne sredine na pragmatičan, održiv i balansiran način.
      </p>`
    },
    {
      title: `Zdravstvo i farmacija`,
      content: `<p>NKO redovno savetuje klijente koji se bave raznim delatnostima u okviru sektora zdravstva i
      farmacije, uključujući proizvođače lekova, veledrogerije, distributere i lance apoteka.
      </p>
      <br>
      <p>Naše usluge u ovom sektoru obuhvataju razne oblasti uključujući i savetovanje u pogledu svih
      regulatornih aspekata koji se tiču industrije. Imamo značajno iskustvo u radu na akvizicijama u ovom
      sektoru, prilagođeno posebnim okolnostima koje postoje u oblasti zdravstva i farmacije. Zahvaljujući
      našem temeljnom poznavanju sektora, pružamo i složene savete iz oblasti prava konkurencije u ovoj
      industriji.
      </p>`
    },
    {
      title: `Nepokretnosti i izgradnja`,
      content: `<p>Specijalizovani smo za pružanje pravnih usluga koje se odnose na razvoj, izgradnju, zakup,
      upravljanje, strukturiranje, finansiranje, kupovinu i raspolaganje komercijalnim, stambenim i
      industrijskim objektima. Imamo značajno iskustvo u primeni FIDIC ugovora prilagođenih lokalnim
      propisima i savetujemo klijente u vezi sa izgradnjom i upravljanjem najvećih šoping centara u regionu.
      </p>
      <br>
      <p>Naša znanja i iskustva u primeni propisa u ovoj oblasti, kao i razumevanje prakse i načina rada
      organa uprave, pomažu nam da savetujemo klijente da na efikasan način ostvare svoje ciljeve, kao i
      da racionalizuju troškove i smanje pravne rizike u najvećoj mogućoj meri.
      </p>`
    },
    {
      title: `Tehnologija, medijsko pravo i telekomunikacije`,
      content: `<p>Primenjujući široko razumevanje trendova, razvoja i inovacija u sektoru telekomunikacija i medija, naš
      cilj je da blisko sarađujemo sa klijentima koji su strateški pokretači u ovoj oblasti. Pružajući
      komercijalno i strateški orijentisane i praktične savete, dajemo značajno novu vrednost transakcijama
      i svakodnevnom poslovanju klijenata iz oblasti telekomunikacija i medija.
      </p>
      <br>
      <p>Naše iskustvo uključuje vođenje najvećih transakcija u oblasti telekomunikacija u regionu, pružanje
      saveta u vezi sa visoko stručnim telekomunikacionim pitanjima, pružanje pravnih usluga u pogledu
      početnih investicija u medijskom sektoru i obezbeđivanja odgovarajuće pravne podrške IT startapima,
      kao i učešće u nekoliko značajnih projekata u vezi sa reformom i unapređenjem regulative koja se
      odnosi na sektor telekomunikacija i medija.
      </p>
  </div>`
    }
  ];

  constructor() { }

  getAreas(language: string) {
    return this[language];
  }
}
