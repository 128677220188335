import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { EmployeeModel } from '../../models/employee.model';
import { TeamService } from '../../services/team.service';

declare var Isotope: any;

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit, AfterViewInit {
  iso: any;
  selectedEmployee: EmployeeModel;
  employees: EmployeeModel[];
  teamSection: HTMLElement;
  type: string;

  constructor(
    private teamService: TeamService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) { }

  ngOnInit() {
    this.employees = this.teamService.getEmployees(this.translate.defaultLang);

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.selectedEmployee = null;
      this.employees = this.teamService.getEmployees(event.lang);
      this.cdr.detectChanges();
      this.iso.reloadItems();
      this.iso.arrange({
        filter: element => true
      });
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.closeDetails();
        this.type = null;  // ovo resetuje klasu grayed
      }
    });
  }

  ngAfterViewInit() {
    const elem = document.querySelector('.team-wrap');
    this.iso = new Isotope(elem, {
      // options
      itemSelector: '.associates',
      layoutMode: 'fitRows'
    });
  }

  setType(type: string) {
    this.type = this.type === type ? null : type;
    this.closeDetails();
    if (type === null && window.innerWidth < 768) {
      this.scrollTo(0);
    }  else if (type === 'senior' && window.innerWidth < 420) {
      this.scrollTo(730);
    } else if (type === 'associates' && window.innerWidth < 420) {
      this.scrollTo(1386);
    } else if (type === 'office' && window.innerWidth < 420) {
      this.scrollTo(1824);
    } else if (type === 'partner' && window.innerWidth < 420) {
      this.scrollTo(290);
    } else if (type === 'senior' && window.innerWidth < 768) {
      this.scrollTo(710);
    } else if (type === 'associates' && window.innerWidth < 768) {
      this.scrollTo(1360);
    } else if (type === 'office' && window.innerWidth < 768) {
      this.scrollTo(1799);
    } else if (type === 'partner' && window.innerWidth < 768) {
      this.scrollTo(266);
    } else if (type === null) {
      this.scrollTo(-134);
    } else if (type === 'partner') {
      this.scrollTo(178);
    } else if (type === 'senior') {
      this.scrollTo(397);
    } else if (type === 'office') {
      this.scrollTo(835);
    } else if (type === 'associates') {
      this.scrollTo(616);
    }  
  }

  select(employee: EmployeeModel, elementId: string) {
    this.selectedEmployee = employee;
    this.type = null;   // ovo resetuje klasu grayed
    this.iso.arrange({
      filter: element => element.id !== elementId
    });
    if (window.innerWidth < 768) {
      this.scrollTo(287);
    } else {
      this.scrollTo(-135);
    }
  }

  closeDetails() {
    this.selectedEmployee = null;
    if (window.innerWidth < 768) {
      this.scrollTo(0);
    }
    this.iso.arrange({
      filter: element => true
    });
  }

  scrollTo(distanceTop) {
    this.teamSection = document.querySelector('app-team') as HTMLElement;
    window.scrollTo({ behavior: 'smooth', top: this.teamSection.offsetTop + distanceTop });
  }
}
