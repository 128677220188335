import 'bingmaps';

import { Component, Input, OnInit } from '@angular/core';
import { LocationModel } from 'src/app/models/location.model';
import { MapLoaderService } from 'src/app/services/map-loader.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  // tslint:disable-next-line:variable-name
  private _location: LocationModel;
  get location(): LocationModel {
    return this._location;
  }
  @Input()
  set location(location: LocationModel) {
    this._location = location;

    if (this.map) {
      this.map.setView({
        center: new Microsoft.Maps.Location(this.location.lat, this.location.long)
      });
    }
  }

  @Input() locations: LocationModel[];

  map: Microsoft.Maps.Map;

  fadedMap = {
    version: '1.0',
    elements: {
      vegetation: {
        visible: false
      },
      transportation: {
        labelOutlineColor: '#ffffff',
        fillColor: '#ffffff',
        strokeColor: '#d7d6d5'
      },
      water: {
        fillColor: '#b1bdd6',
        labelColor: '#ffffff',
        labelOutlineColor: '#9aa9ca'
      },
      structure: {
        fillColor: '#d7d6d5'
      },
    },
    settings: {
      landColor: '#e7e6e5',
      shadedReliefVisible: true
    }
  };

  constructor(
    private mapLoaderService: MapLoaderService
  ) { }

  ngOnInit() {
    this.mapLoaderService.load().then(() => this.initMap());
  }

  private initMap() {
    this.map = new Microsoft.Maps.Map(
      document.getElementById('map'),
      {
        credentials: environment.bingMapsApiKey,

        center: new Microsoft.Maps.Location(this.location.lat, this.location.long),
        zoom: 17,
        disableScrollWheelZoom: true,
        disableZooming: true,
        showLocateMeButton: false,
        showMapTypeSelector: false,
        customMapStyle: this.fadedMap
      }
    );

    for (const location of this.locations) {
      const pin = new Microsoft.Maps.Pushpin(
        new Microsoft.Maps.Location(location.lat, location.long),
        {
          title: 'NKO Law',
          subTitle: 'Office'
        }
      );
      this.map.entities.push(pin);
    }

  }
}



