import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit{

  selectedLanguage = 'english';
  showMenu = false;
  params: string;

  constructor(
    private translate: TranslateService,
    private router: Router
  ) { }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)  
    ).subscribe((event: NavigationEnd) => {
      this.params = event.url;
    });
  }

  navigateTo(id: string): void {
    setTimeout(() => {
      let element = document.getElementById(id);
      element.scrollIntoView({
        behavior: "smooth"
      });
    }, 1);
  }

  changeLanguage() {
    this.selectedLanguage = this.selectedLanguage === 'english' ? 'serbian' : 'english';
    this.translate.use(this.selectedLanguage);

    setTimeout(() => {
      if (document.getElementById('contact') && this.params !== '/legal') {
        let elContact = document.getElementById('contact').children[0].children[0];
        elContact.children[1].classList.remove('out-view');
        elContact.children[2].classList.remove('out-view');
      }
    }, 1);
  }
}

