import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  private english = [
    {
      lat: 44.805323,
      long: 20.466006,
      adress1: 'Njegoševa 11',
      adress2: '11000 Belgrade, Serbia',
      phone: 'T: +381 (0)11 7150 500',
      email: `E: info<span class='letter'>@</span>nko-law.com`,
      linkedin: 'Linkedin: NKO Partners',
      web: 'www.nko-law.com'
    },
    {
      lat: 42.43629975846622,  
      long: 19.263599299420484,
      adress1: 'Bratstva i jedinstva 6/VII',
      adress2: '81000 Podgorica, Montenegro',
      phone: 'T: +382 (0)20 512 629',
      email: `E: info<span class='letter'>@</span>nko-law.com`,
      linkedin: 'Linkedin: NKO Partners',
      web: 'www.nko-law.com'
    }
  ];
  private serbian = [
    {
      lat: 44.805323,
      long: 20.466006,
      adress1: 'Njegoševa 11',
      adress2: '11000 Beograd, Srbija',
      phone: 'T: +381 (0)11 7150 500',
      email: `E: info<span class='letter'>@</span>nko-law.com`,
      linkedin: 'Linkedin: NKO Partners',
      web: 'www.nko-law.com'
    },
    {
      lat: 42.43629975846622,  
      long: 19.263599299420484,
      adress1: 'Bratstva i jedinstva 6/VII',
      adress2: '81000 Podgorica, Crna Gora',
      phone: 'T: +382 (0)20 512 629',
      email: `E: info<span class='letter'>@</span>nko-law.com`,
      linkedin: 'Linkedin: NKO Partners',
      web: 'www.nko-law.com'
    }
  ];

  constructor() { }

  getLocation(language: string) {
    return this[language];
  }
}
