<div class="rankings-wrap" id="rankings">
  <div class="rankings-headline">
    <h1 class="bounce-right-headline out-view" translate="rankings"></h1>
  </div>
  <div class="main-rankings out-view">
    <p translate="mainRankings"></p>
    <img src="../../assets/img/chambers.png" alt="chambers-europe">
    <p translate="chambersEurope">
    </p>
    <img src="../../assets/img/chambers-global.png" alt="chambers-global">
    <p translate="chambersGlobal"></p>
    <img src="../../assets/img/legal-500.png" alt="legal-500">
    <p translate="legal500"></p>
  </div>
</div>
