import { AgmCoreModule } from '@agm/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClientsComponent } from './home/clients/clients.component';
import { ContactComponent } from './home/contact/contact.component';
import { MapComponent } from './home/contact/map/map.component';
import { FirmComponent } from './home/firm/firm.component';
import { HomeComponent } from './home/home.component';
import { PracticeComponent } from './home/practice/practice.component';
import { RankingsComponent } from './home/rankings/rankings.component';
import { TeamComponent } from './home/team/team.component';
import { LayoutComponent } from './layout/layout.component';
import { NavigationComponent } from './navigation/navigation.component';
import { ContactService } from './services/contact.service';
import { MapLoaderService } from './services/map-loader.service';
import { PracticeService } from './services/practice.service';
import { TeamService } from './services/team.service';


@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    FirmComponent,
    TeamComponent,
    PracticeComponent,
    RankingsComponent,
    ClientsComponent,
    ContactComponent,
    MapComponent,
    HomeComponent,
    LayoutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAXqQkyjsquJdubEu90KpIrV0iKiWvLpw4'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [TeamService, PracticeService, MapLoaderService, ContactService],
  bootstrap: [AppComponent]
})
export class AppModule {}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
