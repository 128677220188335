<div class="practice-wrap" id="practice">
  <div class="practice-headline">
    <h1 class="practice-bounce-right-headline out-view" translate="practiceAreas"></h1>
  </div>

  <div class="main-practice">
    <div class="areas-wrap">
      <div class="left-li out-view">
        <ul>
          <li
            class="li-areas"
            [class.selected]="area == selectedArea"
            *ngFor="let area of areas"
            (click)="select(area)"
          >{{ '• ' + area.title }}</li>
        </ul>
      </div>
      <div class="right-text out-view" *ngIf="selectedArea" [innerHtml]="selectedArea.content">
      </div>
    </div>
  </div>
</div>
