import { Component, OnInit } from '@angular/core';

import { LocationModel } from '../../models/location.model';
import { ContactService } from 'src/app/services/contact.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { element } from 'protractor';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  locations = [];
  selectedLocation: LocationModel;

  styles: any[] = [
    {
      featureType: "all",
      elementType: "all",
      stylers: [
        { saturation: -100 } // <-- THIS
      ]
    }
  ]

  constructor(
    private contactService: ContactService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.locations = this.contactService.getLocation(this.translate.defaultLang);
    this.selectedLocation = this.locations[0];

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.locations = this.contactService.getLocation(event.lang);
      this.selectedLocation = this.locations[0];
    });
  }

  setLocation(location: LocationModel) {
    this.selectedLocation = location;
  }

  removeAnimation(): void {
    const elements = document.querySelectorAll('.out-view') as any;
    elements.classList.remove('out-view');
  }
}
