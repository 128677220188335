// tslint:disable:no-string-literal
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const url = `https://www.bing.com/api/maps/mapcontrol?key=${environment.bingMapsApiKey}&callback=onBingLoaded`;

@Injectable({
  providedIn: 'root'
})
export class MapLoaderService {
  private promise: Promise<void>;

  public load() {
    if (!this.promise) {

      this.promise = new Promise(resolve => {

        window['onBingLoaded'] = resolve;

        const node = document.createElement('script');
        node.src = url;
        node.type = 'text/javascript';
        node.async = true;
        node.defer = true;
        document.getElementsByTagName('head')[0].appendChild(node);
      });
    }

    return this.promise;
  }
}
// tslint:enable:no-string-literal
